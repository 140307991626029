exports = module.exports = require("../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._12TXvPxfD5u4FxHwdcByP0 {\n    font-size: 14px;\n}\n\n._12TXvPxfD5u4FxHwdcByP0 div {\n    padding: 10px 15px;\n}\n\n._12TXvPxfD5u4FxHwdcByP0 h4 {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 1.1;\n    margin-bottom: 5px;\n}\n\n._12TXvPxfD5u4FxHwdcByP0 p {\n    line-height: 1.3;\n    margin-bottom: 15px;\n}\n", ""]);

// exports
exports.locals = {
	"sidecarModal": "_12TXvPxfD5u4FxHwdcByP0"
};